import {Album} from "../model/Album";
import cds from "./cd.json";
import vivacecds from "./vivace-albums.json"

type AlbumProps = {
    album: Album
    sampleUrl: string
}

type SellfyProps = {
    cms: {
        digitalURL: string
        digitalID: string
        physicalURL: string
        physicalID: string
    }
}

export type CMSAlbum = AlbumProps & SellfyProps

export const k2b2albums: CMSAlbum[] = [
    {
        album: {
            title: "2 by 2",
            tracks: [
                {name:"Off Minor"},
                {name: "Tonight the Night(for Iannis Xenakis"},
                {name: "Ballad in F"},
                {name: "Criss-Cross"},
                {name: "'Round Midnight"},
                {name: "High Flight"},
                {name: "Well, You Needn't"},
                {name: "Blue as We Can Be"},
                {name: "Cellular Connection"},
                {name: "2 at Once (for La Monte Young)"},
                {name: "Straight, No Chaser / La Nevada"},
                {name: "Tonight The Night"},
                {name: "Off Minor"},
                {name: "Exodust"}
            ],
            books: undefined,
            cast: [
                {name:"Buell Neidlinger",
                role: "Cello"
                }
            ],
            description: "Test Description",
            coverArt: ""
        },
        cms: {
            digitalURL: "https://k2b2-records.sellfy.store/p/2-by-2/",
            digitalID: "bq1v79" ,
            physicalID: "",
            physicalURL: ""
        },
        sampleUrl: "https://k2b2-assets.s3-us-west-1.amazonaws.com/albumPreviews/Anthony+Braxton-Buell+Neidlinger+Disc+One+-+Braxton-Neidlinger+Duets+Disc+1+-+1+Off+MInor.mp3"
    },
    {
        album: {
            title: "Blossom",
            tracks: [
                {name: ""}
            ],
            books: undefined,
            cast: [
                {name: "Alexander Anderson", role: "Piano"}
            ],
            coverArt: "",
            description: ""
        },
        cms: {
            digitalURL: "",
            digitalID: "",
            physicalID: "",
            physicalURL: ""
        },
        sampleUrl: ""
    }
]

export const k2b2AlbumsFromJSON : CMSAlbum[] = cds.map(({cd_name, description, image_url, cms, tracks, sample_mp3, cast}) => {
    return {album: {
            title: cd_name,
            tracks: tracks!.map((track) => {return({name: track.name})}),
            books: undefined,
            cast: cast!.map((cast) => {return({name: cast.name, role: cast.role})}),
            coverArt: image_url,
            description: description
        },
        sampleUrl: sample_mp3,
        cms: {
            digitalURL: cms.digital_link,
            digitalID: cms.digital_id,
            physicalURL: cms.buy_link,
            physicalID: cms.buy_id
        }
    }
})

export const vivaceAlbumsFromJSON : CMSAlbum[] = vivacecds.map(({cd_name, description, image_url, cms, tracks, books, sample_mp3, cast}) => {
    if (books === undefined) {
        return {album: {
                title: cd_name,
                tracks: tracks!.map((track) => {return({name: track.name})}),
                books: undefined,
                cast: cast!.map((cast) => {return({name: cast.name, role: cast.role})}),
                coverArt: image_url,
                description: description
            },
            sampleUrl: sample_mp3,
            cms: {
                digitalURL: cms.digital_link,
                digitalID: cms.digital_id,
                physicalURL: cms.buy_link,
                physicalID: cms.buy_id
            }
        }
    } else {
        return {album: {
                title: cd_name,
                tracks: [],
                books: books!.map((book) => {
                    return ({id: book.id, name: book.name, tracks: book.tracks.map((track) => {
                            return({name: track})
                        })})
                }),
                cast: cast!.map((cast) => {return({name: cast.name, role: cast.role})}),
                coverArt: image_url,
                description: description
            },
            sampleUrl: sample_mp3,
            cms: {
                digitalURL: cms.digital_link,
                digitalID: cms.digital_id,
                physicalURL: cms.buy_link,
                physicalID: cms.buy_id
            }
        }
    }

})