import {CMSAlbum} from "../API/API";
import React, {useState} from "react";
import {animated, useSpring} from "react-spring";
import {
    Accordion, AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    Container, createTheme,
    Dialog, DialogContent,
    DialogTitle,
    Divider,
    Grid, IconButton,
    List, ListItem, ListItemIcon, ListItemText,
    ListSubheader,
    Paper,
    Typography
} from "@mui/material";
import {AlbumArtProps} from "../components/AlbumArtFeature";
import ReactAudioPlayer from "react-audio-player";
import {AccordionList} from "./AccordionList";
import {toTitleString} from "../util/StringHelpers";
import CloseIcon from '@mui/icons-material/Close';


const theme = createTheme({
    palette: {
        primary: {
            main: '#ff4400'
        },
        secondary: {
            main: '#00ff00'
        }
    }
})

const AlbumCompactView= ({album, sampleUrl, cms}: CMSAlbum) => {
    const props = {album, sampleUrl, cms}
    const [modalOpen, setModalOpen] = useState(false)
    const [{ elevation }, set] = useSpring(() => ({ elevation: 1 }))

    const handleTap = () => {
        console.log("handleTap called")
        if(modalOpen) {
            setModalOpen(false)
        } else {
            setModalOpen(true)
        }
    }

    const handleClose = () => {
        setModalOpen(false)
    }

    const coverArtUrl = album.coverArt

    return (
        <>
        <Grid item key={album.title} onClick={handleTap}>
            <animated.div
                onMouseEnter={() => set({ elevation: 5 })}
                onMouseLeave={() => set({ elevation: 1 })}
            >
                <Paper elevation={elevation.get()}
                       sx={{
                        textAlign: 'center',
                        color: theme.palette.text.secondary,
                        cursor: 'pointer',
                        '&:hover': {
                            boxShadow: theme.shadows[5]
                       }}}>
                    <AlbumArtPreview imageURL={coverArtUrl} />
                    <Divider variant={"middle"} />
                    <Button onClick={handleTap}>
                        {album.title}
                    </Button>
                </Paper>
            </animated.div>

        </Grid>
        <AlbumModal key={album.title} albumProps={props} onClose={handleClose} open={modalOpen.valueOf()}/>
        </>
    )
}
interface AlbumModalProps {
    open: boolean
    albumProps: CMSAlbum
    onClose : () => void
}
interface Product {
    id: string
    url: string
    physical: boolean
}
interface DigitalProduct extends Product {}
interface PhysicalProduct extends Product {}

const getTitleFromFileName = (fileName: string) : string =>{
    const parts = fileName.split("/")
    const mp3 = parts[parts.length-1]
    const removePluses = mp3.replace(/\+/g, " ")
    const removeMinuses = removePluses.replace("-", "")
    const removeUnderscores = removeMinuses.replace("_", "")
    return removeUnderscores.replace(".mp3", "")

}

const AlbumModal = (props: AlbumModalProps) => {
    const { open, albumProps, onClose} = props
    const digitalItem : DigitalProduct = { url: albumProps.cms.digitalURL, id: albumProps.cms.digitalID, physical: false }
    const physicalItem: PhysicalProduct = { url: albumProps.cms.physicalURL, id: albumProps.cms.physicalID, physical: true}
    const handleCLose = () => {
        console.log("Dismissing Modal")
        let headScripts = document.getElementsByTagName('script')
        console.log(headScripts)
        headScripts.item(0)
        if (headScripts.item(0) != null) {
            document.head.removeChild(headScripts.item(0)!)
        }
        onClose()
        //other stuff
    }

    return (
        <Dialog onClose={() => handleCLose()} open={open} fullWidth maxWidth={"md"} disableEnforceFocus={true} disableAutoFocus={true} disableRestoreFocus={true}>
            <DialogTitle>{albumProps.album.title}</DialogTitle>
            <DialogContent dividers>
                <Grid container>
                    <Grid item sm={12} md={6} lg={6} xl={6} sx={{padding: 1}}>
                        <Paper sx={{padding: 2}}>
                        <AlbumArtFull imageURL={albumProps.album.coverArt}/>
                        {albumProps.sampleUrl !== "" &&
                            <>
                                <Typography variant={"subtitle2"} sx={{paddingTop: 1, paddingBottom: 1}}>{getTitleFromFileName(albumProps.sampleUrl)}</Typography>
                            <ReactAudioPlayer src={albumProps.sampleUrl} volume={0.5}
                                              controls/>
                            </>
                        }
                        {albumProps.album.description.length > 2 &&
                            <div>
                                <Typography variant={"body1"}>
                                    {albumProps.album.description}
                                </Typography>
                            </div>
                        }
                        </Paper>
                    </Grid>
                    <Grid item sm={12} md={6} lg={6} xl={6} sx={{padding: 1}}>
                        <Paper>
                            <List>
                                <ListSubheader>Tracks</ListSubheader>
                                {albumProps.album.tracks.map((track, index) => (
                                    <ListItem key={track.name}>
                                        <ListItemIcon>{(index+1).toString()}</ListItemIcon>
                                        <ListItemText primary={toTitleString(track.name)}/>
                                    </ListItem>
                                ))}
                                {albumProps.album.books != undefined &&
                                    albumProps.album.books.map((book) => (
                                        <>
                                            <Accordion>
                                                <AccordionSummary>
                                                    <Typography variant={"h6"} color={theme.palette.info.main}>{book.name}</Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    {book.tracks.map((track) => (
                                                        <ListItem key={track.name}>
                                                            <ListItemText primary={toTitleString(track.name)}/>
                                                        </ListItem>
                                                    ))}
                                                </AccordionDetails>
                                            </Accordion>
                                        </>
                                    ))
                                }
                            </List>
                            {/*<AccordionList panelNumber={1} title={"Tracks"} list={albumProps.album.tracks.map((track) => {return track.name})}/>*/}
                            <List>
                                <ListSubheader>Cast</ListSubheader>
                                {albumProps.album.cast.map((castMember, index) => (
                                    <ListItem>
                                        <ListItemIcon>{(index+1).toString()}</ListItemIcon>
                                        <ListItemText primary={toTitleString(castMember.name)} secondary={castMember.role}/>
                                    </ListItem>
                                ))}
                            </List>
                        </Paper>
                    </Grid>
                    <Grid container sx={{textAlign:"center"}}>
                        <Grid item sm={12} md={12} lg={12} xl={12}>
                            <Typography variant={"h5"}>Buy Now</Typography>
                        </Grid>
                    {digitalItem.id !== "" &&
                        <Grid item sm={12} md={6} lg={6} xl={6}>
                            <SellfyAnchor href={digitalItem.url} itemID={digitalItem.id} physical={digitalItem.physical}/>
                        </Grid>
                    }
                    {digitalItem.id === "" &&
                        <Grid item sm={12} md={6} lg={6} xl={6}>
                            <Typography> Digital item not available</Typography>
                        </Grid>
                    }
                    {physicalItem.id !== "" &&
                        <Grid item sm={12} md={6} lg={6} xl={6}>
                            <SellfyAnchor href={physicalItem.url} itemID={physicalItem.id} physical={physicalItem.physical}/>
                        </Grid>
                    }
                    {physicalItem.id === "" &&
                        <Grid item sm={12} md={6} lg={6} xl={6}>
                            <Typography> Compact Disc Unavailable</Typography>
                        </Grid>
                    }
                        <Grid item sm={12} xl={12}>
                            <Typography variant={"h6"}>Digital edition includes mp3, flac, and wav formats, and Liner Notes</Typography>
                        </Grid>

                    </Grid>
                </Grid>
            </DialogContent>
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={() =>
                        {
                            onClose()
                            handleCLose()
                        }
                    }
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </Dialog>
    )
}

interface SellfyAnchorProps {
    href: string
    itemID: string
    physical: boolean
}

class SellfyAnchor extends React.Component<SellfyAnchorProps>{
    state = {
        href: "",
        id: "",
        className: ""
    }
    componentDidMount() {
        this.setState(() => ({
            href: this.props.href.valueOf,
            id: this.props.itemID.valueOf(),
            className:"sellfy-buy-button"
        }))
        const script = document.createElement('script');
        script.src = "https://sellfy.com/js/api_buttons.js";
        script.className = "button_js"
        script.async = true;
        document.body.appendChild(script);
    }
    componentWillUnmount() {
        // const scripts = document.getElementsByClassName("button_js")
        // for (let item in scripts) {
        //     document.removeChild(scripts[0])
        // }
    }

    render() {
        return(
            <a href={this.state.href} data-text={this.props.physical ? "CD" : "Download"} id={this.state.id}
               className={this.state.className} aria-label={this.props.physical ? "CD" : "Download"}> </a>
        )
    }
}



const AlbumArtPreview = ({imageURL}: AlbumArtProps) => {
    return (
        <Box
            component="img"
            sx={{
                paddingTop: 1 ,
                paddingBottom: 2,
                height: '90%',
                width: '90%'
            }}
            alt="album cover"
            src={imageURL}
        />
    )
}
const AlbumArtFull = ({imageURL}: AlbumArtProps) => {
    return (
        <Box
            component="img"
            sx={{
                height: '100%',
                width: '100%',
                maxHeight: '400px',
                maxWidth: '400px'
            }}
            alt="album cover"
            src={imageURL}
        />
    )
}

export default AlbumCompactView

