import React from 'react';
import './App.css';
import {k2b2AlbumsFromJSON, vivaceAlbumsFromJSON} from "./API/API";
import Catalog from "./view/Catalog"
import FeatureCarousel, {FeatureProps} from "./view/FeatureCarousel";
import NavigationBar from "./view/NavigationBar";
import {Box, Container, createTheme, CssBaseline, Grid, Shadows, useMediaQuery} from "@mui/material";
import {ThemeProvider} from "@mui/material/styles";
import {Facebook, YouTube} from "@mui/icons-material";

export const ColorModeContext = React.createContext({ toggleColorMode: () => {} });
//const featuredAlbums = [k2b2AlbumsFromJSON[9], k2b2AlbumsFromJSON[23]]
const vivaceFeatures = [vivaceAlbumsFromJSON[3], vivaceAlbumsFromJSON[9]]

const coolFeatures = vivaceFeatures.map((album) => (
    {
        title: album.album.title,
        descriptionText: album.album.description,
        image: album.album.coverArt
    }
))
const features: FeatureProps = {features: coolFeatures}
const preferDark = (on: boolean) => {
    if(on) {
        return "dark"
    } else {
        return "light"
    }
}
function App() {
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
    const [mode, setMode] = React.useState<'light' | 'dark'>(preferDark(prefersDarkMode));
    const colorMode = React.useMemo(
        () => ({
            toggleColorMode: () => {
                setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
            },
        }),
        [],
    );

    const theme = React.useMemo(
        () =>
            createTheme({
                palette: {
                    background: {
                      default: mode === "light" ? "#eeeeee": "#444444"
                    },
                    mode,
                    text:{
                        primary: mode === "light" ? "#444444" : "#eeeeee"
                    },
                    info: {
                        main: mode === "light" ? "#1133bbbb" : "#ffffff"
                    }

                },

                shadows: Array(25).fill(4.642171315875025) as Shadows

            }),
        [mode],
    );

    return (
        <ColorModeContext.Provider value={colorMode}>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <div className={mode === "light" ? "App bg-alpha-light" : "App bg-alpha-dark" } >
                    <NavigationBar/>
                    <br/><br/><br/><br/><br/><br/>
                    <Container>
                        <Box>
                            <FeatureCarousel features={[features.features[0], features.features[1]]}/>
                        </Box>
                        <Box>
                            <div id={"Catalog"}>
                                <span id="sellfy-shopping-cart" data-id="634749bc94f051efdd6b09f8" data-type="floating" data-text="Cart"></span>
                                <Catalog/>
                            </div>
                        </Box>
                    </Container>
                    <br/>
                    <footer>
                        <Grid container>
                            <Grid item md={12}>
                                © {new Date().getFullYear().toString()} Vivace Records | contact: <a target={"_mailto"} href={"webmaster@k2b2.com"} style={{textDecoration:"none"}} className={"App-link"}>webmaster@k2b2.com</a>
                            </Grid>
                        </Grid>

                    </footer>
                </div>
            </ThemeProvider>
        </ColorModeContext.Provider>
    );
}

export default App;
