import {k2b2AlbumsFromJSON as k2b2Albums, vivaceAlbumsFromJSON as vivaceAlbums} from "../API/API";
import {Grid} from "@mui/material";
import React from "react";
import {Album} from "../model/Album";
import AlbumCompactView from "./AlbumCompactView";

export const K2B2AlbumListView = () => {

    return (
        <Grid container spacing={3}>
            {k2b2Albums.map((albumProps, index) => (
                <Grid item key={index} lg={3} md={4} xl={3} sm={6} xs={6}>
                <AlbumCompactView album={albumProps.album as Album}  sampleUrl={albumProps.sampleUrl} cms={albumProps.cms} />
                </Grid>
            )).reverse()}
        </Grid>
    )
}

export const VivaceAlbumListView = () => {
    return (
        <Grid container spacing={3}>
            {vivaceAlbums.map((albumProps, index) => (
                <Grid item key={index} lg={3} md={4} xl={3} sm={6} xs={6}>
                    <AlbumCompactView album={albumProps.album as Album}  sampleUrl={albumProps.sampleUrl} cms={albumProps.cms} />
                </Grid>
            )).reverse()}
        </Grid>
    )
}