export const toTitleString = (s: string) : string => {
    const words = s.split(" ")
    var title: string = ""

    for(var word of words) {
        for(let i = 0; i < word.length; i++){
            if( i === 0){
                title += word[i].toUpperCase()
            } else {
                title += word[i].toLowerCase()
            }
        }
        title += " "
    }
    return title
}