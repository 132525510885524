import {Divider, Grid} from "@mui/material";

export const DividerMedium = () => {
    return(
        <Grid container sx={{paddingBottom: 10}}>
            <Grid item xs={12}>
                <Divider sx={{width: "50%",
                    margin: "0 auto"
                }}/>
            </Grid>
        </Grid>
    )
}