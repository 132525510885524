import React, {useEffect, useState} from "react"
import {Box, Button, Container, Grid, Paper, Typography} from "@mui/material";
import AlbumArtFeature from "../components/AlbumArtFeature";
import {DividerMedium} from "../components/DividerMedium";
import Carousel from "react-material-ui-carousel";
import {ArrowBack, ArrowForward} from "@mui/icons-material";


const ANIMATION_DURATION = 1000; // The duration of the animation in milliseconds
const DELAY = 15000; // The delay before the animation starts in milliseconds

export interface FeatureProps {
    features: CarouselItem[]
}
export const FeatureCarousel = (featureProps: FeatureProps) => {
    const features = featureProps.features

    return (
                <Carousel
                    NextIcon={<ArrowForward/>}
                    PrevIcon={<ArrowBack/>}
                    interval={25000}
                    next={() => {

                    }}
                    prev={() => {

                    }}
                >
                    {features.map((feature) => (
                        <CarouselItem key={feature.title} title={feature.title} image={feature.image} descriptionText={feature.descriptionText}/>
                    ))
                    }
                </Carousel>

    )
}

export interface CarouselItem{
    title: string
    image: string
    descriptionText: string
}

const CarouselItem = (item: CarouselItem) => {
    return (
        <div>
            <Paper sx={{padding: 5, borderRadius: 5}} elevation={2}>
                <Container>
                <Grid container>
                    <Grid item xs = {12} md={6}>
                        <AlbumArtFeature imageURL={item.image}/>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography variant={"h4"}>{item.title}</Typography>
                        <br/>
                            <pre style={{
                                overflow: "auto",
                                margin: "0px 0px 0px 0px",
                                padding: "5px 5px 3px 5px",
                                whiteSpace: "pre-wrap"
                            }}>
                            {item.descriptionText}
                                </pre>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <Button href={"#Catalog"} variant={"contained"}>View Catalog</Button>
                    </Grid>
                </Grid>
                </Container>
            </Paper>
            <br/>
            <DividerMedium />
        </div>
    )
}
export const AnimatingCarousel = () => {
    const [animationState, setAnimationState] = useState('initial');

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setAnimationState('final');
        }, DELAY);

        return () => {
            clearTimeout(timeoutId);
        }
    }, []);

    return (
        <div>
            <div style={{
                transition: `transform ${ANIMATION_DURATION}ms linear`,
                transform: animationState === 'initial' ? 'none' : 'translateX(-100%)'
            }}>
                <CarouselItem title={"test title"} descriptionText={"test text"} image={"test image"}/>
            </div>
        </div>
    )
}

export default FeatureCarousel