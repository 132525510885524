import {Box} from "@mui/material";
import React from "react";

export type AlbumArtProps = {
    imageURL: string
}
const AlbumArtFeature = ({imageURL}: AlbumArtProps) => {
    return (
        <Box
            component="img"
            sx={{
                paddingTop: 1 ,
                paddingBottom: 2,
                paddingRight: 3,
                paddingLeft: 3,
                borderRadius: 10,
                height: '100%',
                width: '100%'
            }}
            alt="album cover"
            src={imageURL}
        />
    )
}

export default AlbumArtFeature