import React from "react"
import {Container, Divider, Typography} from "@mui/material";
import {K2B2AlbumListView, VivaceAlbumListView} from "./AlbumListView";
import {DividerMedium} from "../components/DividerMedium";

const Catalog = () => {
    return (
        <Container>
            <Typography variant={"h3"} sx={{paddingBottom: 5}}> Catalog </Typography>
            <VivaceAlbumListView/>
        </Container>
    )
}

export default Catalog